import { API_USERS, API_RESET_PASSWORD, API_GET_DOWNLOAD_USERS } from './urls';
import IApi from 'modules/shared/domain/IApi';
import api from 'modules/shared/infrastructure/api/Api';
import UsersDTO from '../dto/UsersDTO';
import IUsersRepository from '../../domain/IUsersRepository';
import BaseRepository from 'modules/shared/infrastructure/repositories/BaseRepository';

class UsersRepository extends BaseRepository implements IUsersRepository {
  private readonly _api: IApi;
  private readonly _apiUsers: string;
  private readonly _apiResetPassword: string;
  private readonly _apiGetDownloadUsers: string;

  constructor({ api, apiUsers, apiResetPassword, apiGetDownloadUsers }) {
    super();
    this._api = api;
    this._apiUsers = apiUsers;
    this._apiResetPassword = apiResetPassword;
    this._apiGetDownloadUsers = apiGetDownloadUsers;
  }
  get({ queryParams }: { queryParams: object }) {
    const url = `${this._apiUsers}${this._createQueryParams(queryParams)}`;
    return this._api.get(url).then((res) => new UsersDTO(res));
  }

  post(body) {
    const url = this._apiUsers;
    return this._api.post(url, body).then((res) => res);
  }

  updateUser(body) {
    const url = this._apiUsers;
    return this._api.put(url, body).then((res) => res);
  }

  resetPassword(userId) {
    const url = this._apiResetPassword;
    return this._api.put(url + `/${userId}`).then((res) => res);
  }
  deleteUser(userId) {
    const url = this._apiUsers;
    return this._api.delete(url + `/${userId}`).then((res) => res);
  }

  downloadUsers({ queryParams }) {
    const url = `${this._apiGetDownloadUsers}${this._createQueryParams(
      queryParams
    )}`;
    const headers = {
      Accept:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    };
    return this._api.getBlob({ url, headers });
  }
}

export default new UsersRepository({
  api: api,
  apiUsers: API_USERS,
  apiResetPassword: API_RESET_PASSWORD,
  apiGetDownloadUsers: API_GET_DOWNLOAD_USERS
});

export { UsersRepository };
