const INBOUND_BASE_URL = process.env.REACT_APP_INBOUND_API_BASE_URL;
const INBOUND_USERS = `${INBOUND_BASE_URL}/user`;
const MESSAGES = '/messages';
const SEARCH = '/search';
const USER_DASHBOARDS = '/master/master-dashboards';
const PROVIDERS = '/providers';
const ALL_PROVIDERS = '/users/providers';
const LAST_UPDATES = '/last-updates';
const LOGIN_WEB = '/master/login-web';
const API_GET_INBOUND_MESSAGES = `${INBOUND_BASE_URL}${MESSAGES}${SEARCH}`;
const API_POST_MESSAGE = `${INBOUND_BASE_URL}${MESSAGES}`;
const API_DOWNLOAD_PROVIDER_CODES = `${INBOUND_BASE_URL}${PROVIDERS}/download-codes`;
const API_UPLOAD_PROVIDER_CODES = `${INBOUND_BASE_URL}${PROVIDERS}/upload-codes`;
const API_PROVIDER_CONTACT_LIST = `${INBOUND_BASE_URL}${PROVIDERS}`;
const API_LAST_UPDATES = `${INBOUND_BASE_URL}${LAST_UPDATES}`;
const API_POST_LOGIN_WEB = `${INBOUND_BASE_URL}${LOGIN_WEB}`;
const API_GET_ALL_PROVIDERS = `${INBOUND_BASE_URL}${ALL_PROVIDERS}`;
const API_GET_USER_DASHBOARDS = `${INBOUND_BASE_URL}${USER_DASHBOARDS}`;

export {
  INBOUND_USERS,
  API_GET_INBOUND_MESSAGES,
  API_POST_MESSAGE,
  API_GET_USER_DASHBOARDS,
  API_DOWNLOAD_PROVIDER_CODES,
  API_UPLOAD_PROVIDER_CODES,
  API_PROVIDER_CONTACT_LIST,
  API_LAST_UPDATES,
  API_POST_LOGIN_WEB,
  API_GET_ALL_PROVIDERS
};
