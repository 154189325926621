import {
  API_GET_USER_DASHBOARDS,
  API_POST_LOGIN_WEB,
  INBOUND_USERS
} from 'modules/inbound/shared/infraestructure/repositories/url';
import IApi from 'modules/shared/domain/IApi';
import api from 'modules/shared/infrastructure/api/Api';
import IUserInboundRepository from '../../domain/UserInbound/IUserInboundRepository';
import UserInboundDTO from '../dto/UserInboundDTO';
import UserDashboardsDTO from '../dto/UserDashboardsDTO';

class InboundUserRepository implements IUserInboundRepository {
  private readonly _api: IApi;
  private readonly _apiGetUsers: string;
  private readonly _apiGetUserDashboards: string;
  private readonly _apiPostLoginWeb: string;
  constructor({ api, apiGetUsers, apiGetUserDashboards, apiPostLoginWeb }) {
    this._api = api;
    this._apiGetUsers = apiGetUsers;
    this._apiGetUserDashboards = apiGetUserDashboards;
    this._apiPostLoginWeb = apiPostLoginWeb;
  }

  getInboundUserData() {
    const url = this._apiGetUsers;
    return this._api.get(url).then((res) => {
      return new UserInboundDTO(res);
    });
  }

  getUserDashboards() {
    const url = this._apiGetUserDashboards;
    return this._api.get(url).then((res) => new UserDashboardsDTO(res));
  }

  postLoginWeb() {
    return this._api.post(this._apiPostLoginWeb);
  }
}

export default new InboundUserRepository({
  api: api,
  apiGetUsers: INBOUND_USERS,
  apiGetUserDashboards: API_GET_USER_DASHBOARDS,
  apiPostLoginWeb: API_POST_LOGIN_WEB
});

export { InboundUserRepository };
