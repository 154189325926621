import { IMasterRepository } from '../domain/IMastersRepository';
import { MastersPanelKeys } from '../domain/MastersPanel';
import masterRepository from '../infrastructure/repositories/MastersRepository';

class EditMasterResponsibleUseCase {
  private readonly mastersRepository: IMasterRepository;

  constructor({ repository }) {
    this.mastersRepository = repository;
  }

  execute({
    body
  }: {
    body: {
      master_name: MastersPanelKeys;
      name: string;
      email: string;
      affected_panels: number[];
    };
  }) {
    return this.mastersRepository
      .updateMasterResponsible({ body })
      .then((res) => res);
  }
}

export { EditMasterResponsibleUseCase };
export default new EditMasterResponsibleUseCase({
  repository: masterRepository
});
