import IUserInboundRepository from 'modules/inbound/shared/domain/UserInbound/IUserInboundRepository';
import UserDashboardsData from '../../domain/UserInbound/UserDashboardsData';
import inboundUserRepository from 'modules/inbound/shared/infraestructure/repositories/InboundUserRepository';

class GetUserDashboardsDataService {
  private readonly _inboundRepository: IUserInboundRepository;

  constructor({ repository }) {
    this._inboundRepository = repository;
  }

  execute() {
    return this._inboundRepository
      .getUserDashboards()
      .then((res) => UserDashboardsData.generateFromDTO(res));
  }
}

export default new GetUserDashboardsDataService({
  repository: inboundUserRepository
});

export { GetUserDashboardsDataService };
