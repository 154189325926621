import { createAsyncThunk } from '@reduxjs/toolkit';
import DownloadMasterUseCase from '../../../application/DownloadMasterUseCase';
import { MastersPanel, MastersPanelKeys } from '../../../domain/MastersPanel';
import convertToBase64 from 'modules/inbound/shared/domain/ConvertToBase64';
import UploadMasterUseCase from '../../../application/UploadMasterUseCase';
import { getMasterExcelFile, getTargetMasterPanel } from '.';
import GetMastersDataUseCase from '../../../application/GetMastersDataUseCase';
import EditMasterResponsibleUseCase from '../../../application/EditMasterResponsibleUseCase';
import getUserDashboardsDataService from 'modules/inbound/shared/application/UserInboundUseCases/GetUserDashboardsDataService';
import { SelectItemProps } from 'modules/shared/layout/elements/Selects/typing';

const getMastersData = createAsyncThunk(
  `mastersData/getMastersData`,
  async () => {
    const response = await GetMastersDataUseCase.execute();
    return response;
  }
);

const downloadMaster = createAsyncThunk(
  'mastersData/downloadMaster',
  async (_, { getState }) => {
    const appState = getState();
    const targetMasterPanel: MastersPanel = getTargetMasterPanel(appState);
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const queryParams = {
      tz: timeZone
    };
    const response = await DownloadMasterUseCase.execute({
      queryParams,
      manageMasterUrl: targetMasterPanel.manageMasterUrl!
    });
    return response;
  }
);

const uploadMaster = createAsyncThunk(
  `mastersData/uploadMaster`,
  async (_, { getState }) => {
    const appState = getState();
    const targetMasterPanel: MastersPanel = getTargetMasterPanel(appState);
    const file: File = getMasterExcelFile(appState);
    const base64File = await convertToBase64(file);
    const response = await UploadMasterUseCase.execute({
      file: base64File,
      manageMasterUrl: targetMasterPanel.manageMasterUrl
    });
    return response;
  }
);

const editMasterResponsibleData = createAsyncThunk(
  'mastersData/editMasterResponsibleData',
  async (responsibleUpdateData: {
    masterName: MastersPanelKeys;
    name: string;
    email: string;
    affectedPanels: SelectItemProps[];
  }) => {
    const response = await EditMasterResponsibleUseCase.execute({
      body: {
        master_name: responsibleUpdateData.masterName,
        name: responsibleUpdateData.name,
        email: responsibleUpdateData.email,
        affected_panels: responsibleUpdateData.affectedPanels.map((dashboard) =>
          Number(dashboard.key)
        )
      }
    });
    return response;
  }
);

const getUserDashboards = createAsyncThunk(
  'mastersData/getUserDashboards',
  async () => {
    const response = await getUserDashboardsDataService.execute();
    return response;
  }
);

export {
  getMastersData,
  downloadMaster,
  uploadMaster,
  editMasterResponsibleData,
  getUserDashboards
};
