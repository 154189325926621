import { createSlice } from '@reduxjs/toolkit';
import { MastersState } from './types';
import {
  downloadMaster,
  editMasterResponsibleData,
  getMastersData,
  getUserDashboards,
  uploadMaster
} from './async';
import { downloadXlsxFile } from 'modules/shared/services/downloadXlsxFile';
import GetDateFormatedService from 'modules/inbound/shared/domain/GetDateFormatedService';
import { MastersDownloadNames } from '../../../domain/MastersPanel';

export const initialState: MastersState = {
  mastersData: [],
  requestStatusMastersData: '',
  targetMasterPanel: null,
  masterFile: null,
  downloadRequestStatus: '',
  uploadExcelFile: null,
  uploadFileModalOpen: false,
  uploadRequestStatus: '',
  uploadRequestResponse: {
    hasError: false,
    errorMessage: null
  },
  targetMasterResponsible: {
    masterName: null,
    responsible: { name: '', email: '' },
    affectedPanels: []
  },
  isOpenMasterResponsibleEditModal: false,
  editMasterResponsibleRequestStatus: '',
  dashboards: [],
  dashboardsRequestStatus: ''
};

const mastersDataSlice = createSlice({
  name: 'mastersData',
  initialState,
  reducers: {
    updateTargetMasterPanel: (state, action) => {
      state.targetMasterPanel = action.payload;
    },
    resetDownloadStatus: (state) => {
      state.downloadRequestStatus = initialState.downloadRequestStatus;
    },
    updateIsFileModalOpen: (state, action) => {
      state.uploadFileModalOpen = action.payload;
    },
    updateSelectedFile: (state, action) => {
      state.uploadExcelFile = action.payload;
    },
    updateTargetMasterResponsible: (state, action) => {
      state.targetMasterResponsible = action.payload;
    },
    updateIsOpenMasterResponsibleEditModal: (state, action) => {
      state.isOpenMasterResponsibleEditModal = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getMastersData.fulfilled, (state, action) => {
      state.mastersData = action.payload.mastersData;
      state.requestStatusMastersData = action.meta.requestStatus;
    });
    builder.addCase(getMastersData.pending, (state, action) => {
      state.requestStatusMastersData = action.meta.requestStatus;
    });
    builder.addCase(getMastersData.rejected, (state, action) => {
      state.requestStatusMastersData = action.meta.requestStatus;
    });
    builder.addCase(downloadMaster.fulfilled, (state, action) => {
      state.masterFile = action.payload;
      downloadXlsxFile({
        file: action.payload,
        fileName: `M_${
          MastersDownloadNames[state.targetMasterPanel?.key!]
        }_${GetDateFormatedService.formatCompleteISODate()}`
      });
      state.downloadRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(downloadMaster.pending, (state, action) => {
      state.downloadRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(downloadMaster.rejected, (state, action) => {
      state.downloadRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(uploadMaster.fulfilled, (state, action) => {
      state.uploadRequestResponse = action.payload;
      state.uploadRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(uploadMaster.pending, (state, action) => {
      state.uploadRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(uploadMaster.rejected, (state, action) => {
      state.uploadRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(editMasterResponsibleData.fulfilled, (state, action) => {
      state.editMasterResponsibleRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(editMasterResponsibleData.pending, (state, action) => {
      state.editMasterResponsibleRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(editMasterResponsibleData.rejected, (state, action) => {
      state.editMasterResponsibleRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(getUserDashboards.fulfilled, (state, action) => {
      state.dashboards = action.payload.dashboards;
      state.dashboardsRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(getUserDashboards.pending, (state, action) => {
      state.dashboardsRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(getUserDashboards.rejected, (state, action) => {
      state.dashboardsRequestStatus = action.meta.requestStatus;
    });
  }
});

export const getMastersPanelData = (state) => state.mastersState.mastersData;
export const getMastersDataIsPending = (state) =>
  state.mastersState.requestStatusMastersData === 'pending';
export const getMasterDataIsRejected = (state) =>
  state.mastersState.requestStatusMastersData === 'rejected';
export const getTargetMasterPanel = (state) =>
  state.mastersState.targetMasterPanel;
export const getDownloadMasterIsFulfilled = (state) =>
  state.mastersState.downloadRequestStatus === 'fulfilled';
export const getDownloadMasterIsPending = (state) =>
  state.mastersState.downloadRequestStatus === 'pending';
export const getDownloadRequestError = (state) =>
  state.mastersState.downloadRequestStatus === 'rejected';
export const getHasErrorUpload = (state) =>
  state.mastersState.uploadRequestStatus === 'rejected';
export const getUploadRequestResponse = (state) =>
  state.mastersState.uploadRequestResponse;
export const getIsUploadFulfilled = (state) =>
  state.mastersState.uploadRequestStatus === 'fulfilled';
export const getIsLoadingUpload = (state) =>
  state.mastersState.uploadRequestStatus === 'pending';
export const getIsUploadFileModalOpen = (state) =>
  state.mastersState.uploadFileModalOpen;
export const getMasterExcelFile = (state) => state.mastersState.uploadExcelFile;
export const getTargetMasterResponsible = (state) =>
  state.mastersState.targetMasterResponsible;
export const getIsOpenMasterResponsibleEditModal = (state) =>
  state.mastersState.isOpenMasterResponsibleEditModal;
export const getEditMasterResponsibleSuccess = (state) =>
  state.mastersState.editMasterResponsibleRequestStatus === 'fulfilled';
export const getEditMasterResponsibleFailed = (state) =>
  state.mastersState.editMasterResponsibleRequestStatus === 'rejected';
export const getDashboards = (state) => state.mastersState.dashboards;

export const {
  updateTargetMasterPanel,
  resetDownloadStatus,
  updateIsFileModalOpen,
  updateSelectedFile,
  updateTargetMasterResponsible,
  updateIsOpenMasterResponsibleEditModal
} = mastersDataSlice.actions;
export default mastersDataSlice.reducer;
