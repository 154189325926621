import { Translation } from 'infrastructure/i18n/typing';
import { MastersTranslations } from './typing';

export const MastersText: Translation<MastersTranslations> = {
  es: {
    updateData: 'Actualizar datos',
    updateDataAlert:
      '¿Está seguro de que desea actualizar los siguientes datos?',
    updateSuccessful: 'Los datos se han actualizado correctamente.',
    updateRejected: 'Ocurrió un error al actualizar los datos.',
    haveNullValues: (fields) =>
      `Los campos ${fields.join(', ').toUpperCase()} tienen valores nulos.`,
    haveInvalidValues: (fields) =>
      `Los campos ${fields.join(', ').toUpperCase()} tienen valores inválidos.`,
    haveInvalidEnumValues: (fields) =>
      `Los campos ${fields
        .join(', ')
        .toUpperCase()} tienen valores ENUM inválidos.`,
    haveTooLongValues: (fields) =>
      `Los campos ${fields
        .join(', ')
        .toUpperCase()} tienen valores demasiado largos.`,
    haveRepeatedValues: (fields) =>
      `Los campos ${fields.join(', ').toUpperCase()} tienen valores repetidos.`,
    lastUpdate: 'Última actualización: ',
    fieldsNotFound: (fields) =>
      `Los campos ${fields
        .join(', ')
        .toUpperCase()} no se encontraron en el archivo.`,
    masterBodyshopLineCalendar: 'Calendario Chapa',
    masterBodyworkProgram: 'Programa Chapa DD',
    masterModelLineCalendar: 'Calendario montaje línea',
    masterPieceWarehouseZf: 'Clave Taller Factoría BCN',
    masterModelBodyworkZf: 'Factoría BCN',
    masterGroupLine: 'Grupos de línea',
    masterJit: 'Maestros JIT',
    masterOldWarehouseCapacity: 'Capacidad del Almacén',
    masterModelLineZf: 'Maestro Modelo Línea ZF',
    masterTactoTime: 'Maestro Tiempo Tacto',
    masterWarehouseLineZf: 'Maestro líneas productivas ZF',
    mastersPanel: 'Panel Maestros',
    masterInfo: 'Información sobre los Maestros',
    masterPanelName: 'Nombre Panel Maestros',
    lastUpdateDate: 'Fecha última actualización',
    responsibleSEAT: 'Responsable SEAT',
    responsibleEmail: 'Correo responsable',
    editInfo: (master) => `Editar información ${master}`,
    cancel: 'Cancelar',
    editMaster: 'Editar maestro',
    fillToUpdateMaster:
      'Rellena los campos que hay a continuación para actualizar la información del maestro.',
    updatedBy: 'Actualizado por: ',
    uploadExcelFile: 'Cargar un archivo Excel',
    affectedPanels: 'Paneles afectados'
  },
  en: {
    updateData: 'Uppdate data',
    updateDataAlert: 'Are you sure you want to update the following data?',
    updateSuccessful: "Les dades s'han actualitzat correctament.",
    updateRejected: 'An error occurred while updating the data.',
    haveNullValues: (fields) =>
      `The fields ${fields.join(', ').toUpperCase()} have null values.`,
    haveInvalidValues: (fields) =>
      `The fields ${fields.join(', ').toUpperCase()} have invalid values.`,
    haveInvalidEnumValues: (fields) =>
      `The fields ${fields.join(', ').toUpperCase()} have invalid ENUM values.`,
    haveTooLongValues: (fields) =>
      `The fields ${fields.join(', ').toUpperCase()} have too long values.`,
    haveRepeatedValues: (fields) =>
      `The fields ${fields.join(', ').toUpperCase()} have repeated values.`,
    lastUpdate: 'Last Update: ',
    fieldsNotFound: (fields) =>
      `The fields ${fields
        .join(', ')
        .toUpperCase()} are not found in the file.`,
    masterBodyshopLineCalendar: 'Bodyshop Calendar',
    masterBodyworkProgram: 'DD Bodyshop',
    masterModelLineCalendar: 'Line assembly calendar',
    masterPieceWarehouseZf: 'Code Workshop Factory BCN',
    masterModelBodyworkZf: 'Factory BCN',
    masterGroupLine: 'Line Groups',
    masterJit: 'Masters Jit',
    masterOldWarehouseCapacity: 'Warehouse Capacity',
    masterModelLineZf: 'Master Model ZF Line',
    masterTactoTime: 'Master Tacto Time',
    masterWarehouseLineZf: 'Master ZF production lines',
    mastersPanel: 'Masters Panel',
    masterInfo: 'Master Information',
    masterPanelName: 'Master Panel Name',
    lastUpdateDate: 'Last update date',
    responsibleSEAT: 'SEAT responsible',
    responsibleEmail: 'Responsible email',
    editInfo: (master) => `Edita information ${master}`,
    cancel: 'Cancel',
    editMaster: 'Edit master',
    fillToUpdateMaster:
      'Fill out the fields below to update your master information.',
    updatedBy: 'Updated by:',
    uploadExcelFile: 'Upload an Excel file',
    affectedPanels: 'Affected panels'
  },
  ca: {
    updateData: 'Actualitzar dades',
    updateDataAlert: 'Esteu segur que voleu actualitzar les dades següents?',
    updateSuccessful: 'The data has been successfully updated.',
    updateRejected: "S'ha produït un error en actualitzar les dades.",
    haveNullValues: (fields) =>
      `Els camps ${fields.join(', ').toUpperCase()} tenen valors nuls.`,
    haveInvalidValues: (fields) =>
      `Els camps ${fields.join(', ').toUpperCase()} tenen valors invàlids.`,
    haveInvalidEnumValues: (fields) =>
      `Els camps ${fields
        .join(', ')
        .toUpperCase()} tenen valors ENUM invàlids.`,
    haveTooLongValues: (fields) =>
      `Els camps ${fields.join(', ').toUpperCase()} tenen valors massa llargs.`,
    haveRepeatedValues: (fields) =>
      `Els camps ${fields.join(', ').toUpperCase()} tenen valors repetits.`,
    lastUpdate: 'Última actualització',
    fieldsNotFound: (fields) =>
      `Els camps ${fields
        .join(', ')
        .toUpperCase()} no s'han trobat en el fitxer.`,
    masterBodyshopLineCalendar: 'Calendari Xapa',
    masterBodyworkProgram: 'Programa Xapa DD',
    masterModelLineCalendar: 'Calendari muntatge línia',
    masterPieceWarehouseZf: 'Clau Taller Factoria BCN',
    masterModelBodyworkZf: 'Factoria BCN',
    masterGroupLine: 'Grups de línia',
    masterJit: 'Umbrals Jit',
    masterOldWarehouseCapacity: 'Capacitat del Magatzem',
    masterModelLineZf: 'Mestre Model Línia ZF',
    masterTactoTime: 'Mestre Temps Tacte',
    masterWarehouseLineZf: 'Mestre línies productives ZF',
    mastersPanel: 'Panell Mestres',
    masterInfo: 'Informació sobre els Mestres',
    masterPanelName: 'Nom Panell Mestres',
    lastUpdateDate: 'Data darrera actualització',
    responsibleSEAT: 'Responsable SEAT',
    responsibleEmail: 'Correu responsable',
    editInfo: (master) => `Editar informació ${master}`,
    cancel: 'Cancel.la',
    editMaster: 'Editar mestre',
    fillToUpdateMaster:
      'Omple els camps següents per actualitzar la informació del mestre.',
    updatedBy: 'Actualitzat per:',
    uploadExcelFile: 'Carregar un fitxer Excel',
    affectedPanels: 'Panells afectats'
  }
};
