import GetDateFormatedService from 'modules/inbound/shared/domain/GetDateFormatedService';
import { NO_DATA } from 'modules/inbound/shared/domain/NameValues';

interface ResponsibleDTO {
  name: string;
  email: string;
}

export interface UpdaterDTO {
  name: string;
  email: string | null;
}

export interface MasterDataPanelDTO {
  master_name: string;
  last_update: string;
  responsible: ResponsibleDTO | null;
  updater: UpdaterDTO | null;
  affected_panels: number[];
}

export interface MastersDataDTO {
  masters_data: MasterDataPanelDTO[];
}

export const mastersDataDTOMapper = (data: any): MastersDataDTO => ({
  masters_data: data.map((master) => ({
    master_name: master.master_name,
    last_update: master.last_update
      ? `${GetDateFormatedService.getDateFormated(
          master.last_update,
          'de-DE'
        )} | ${GetDateFormatedService.getTimeFormated(
          `${master.last_update}Z`
        )}`
      : NO_DATA,
    responsible: {
      name: master.responsible ? master.responsible.name : NO_DATA,
      email: master.responsible ? master.responsible.email : NO_DATA
    },
    updater: {
      name: master.updater?.name,
      email: master.updater?.email
    },
    affected_panels: master.affected_panels ?? [NO_DATA]
  }))
});
